import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 120 160">
    <title>Logo</title>
    <g id="Logo" transform="translate(-8.000000, -2.000000)">

          <path fill="none" stroke="#64FFDA" strokeWidth="11.713" strokeMiterlimit="10" d="M36.2,119.3V56l24.5-14.1l24.5,14.1l0,28.3
	L60.7,98.3L36.3,84.2l0.1-63.4L60.7,6.8l54.9,31.6l0.1,63.3l-54.8,31.7L5.9,101.8L5.9,38.5l30.5-17.7" />
      </g>

  </svg>
);

export default IconLogo;
