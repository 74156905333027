module.exports = {
  siteTitle: 'Michael Frenkel | Software Engineer',
  siteDescription:
    'Michael Frenkel is a software engineer based in San Francisco, CA who specializes in developing (and occasionally designing) exceptional, high-quality websites and applications.',
  siteKeywords:
    'Michael Frenkel, Michael, Frenkel, mishafrenkel, software engineer, front-end engineer, web developer, javascript, hack reactor',
  siteUrl: 'https://mikefrenkel.com',
  siteLanguage: 'en_US',

  googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk',

  name: 'Michael Frenkel',
  location: 'San Francisco, CA',
  email: 'mishafrenkel@gmail.com',
  github: 'https://github.com/mishafrenkel',
  socialMedia: [
    {
      name: 'Github',
      url: 'https://github.com/mishafrenkel',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/michael-frenkel-1a540752/',
    },
    {
      name: 'Codepen',
      url: 'https://codepen.io/mishafrenkel/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/mishafrenkel/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/mikebeingfrank',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '#about',
    },
    {
      name: 'Experience',
      url: '#jobs',
    },
    {
      name: 'Work',
      url: '#projects',
    },
    {
      name: 'Contact',
      url: '#contact',
    },
    // {
    //   name: 'Blog',
    //   url: '#blog',
    // },
  ],

  twitterHandle: '@mikebeingfrank',
  googleAnalyticsID: 'UA-45666519-2',

  navHeight: 100,

  greenColor: '#64ffda',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
