import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
    <title>Loader Logo</title>
    <g>
      {/* <g id="M" transform="translate(11.000000, 5.000000)"> */}
        {/* <text
          fill="#64FFDA"
        >
        M
        </text> */}
    {/* <path
         fill="#64FFDA"
      d="M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z"
    /> */}
    <path fill="none" stroke="#64FFDA" strokeWidth="11.713" strokeMiterlimit="10" d="M36.2,119.3V56l24.5-14.1l24.5,14.1l0,28.3
	L60.7,98.3L36.3,84.2l0.1-63.4L60.7,6.8l54.9,31.6l0.1,63.3l-54.8,31.7L5.9,101.8L5.9,38.5l30.5-17.7" />
  </g>
  </svg>

);

export default IconLoader;
